body{
    text-align: center;
    margin: 0px;
    padding: 0px;
    border: 0px;
    max-width: 100vw;
    min-height:950px;
    max-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -moz-font-feature-settings: initial;
    -webkit-font-feature-settings: normal;
    background: rgba(47, 1, 1, 0.11);
    overflow-x: hidden;
}


.center .subheader{
    font-size: 35px;
    text-align: center;

}

.center h1{
    text-align: center;
    font-size: 25px;
}


/*cajita

 */
#sidebar{

    width: 100vh;
    height: 380px;
    margin-top: 75px;
    margin-left:  480px;
    margin-bottom: 32px;
    padding: 8px;
    background: rgba(255, 255, 255, 0.842);
    color: #135081;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.308);
    font-size: 25px;
    font-weight:300;
    border-radius: 10px;
    transition: 1s;
}



#sidebar.archivosAdminCenter{

    float: none;
    margin-top: -380px;
    margin-left: 550px;
    min-height: 550px;
    height: auto;
    width: 900px;
    color: #135081;
}

#sidebar.archivosAdminCenter3{

    float: none;
    margin-left: auto;
    margin-right: auto;
    min-height: 350px;
    height: auto;
    width: 500px;
    color: #138153;
}



.btn{

    background: #6b00b3;
    color: rgb(255, 255, 255);
    padding:15px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 25px;
    text-decoration: none;
    max-width: 1220px;
    transition: 200ms;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    float: none;
}


#btn-table{

    min-width: 210px;
    max-width: 210px;
    min-height: 60px;
    font-size: 20px;

}

.btn:hover{
    background: #2a0046;
    color: #138153;
}


/* Barrita*/

.table{
    padding-bottom: 15px;
    padding-top: 12px;
    margin-top: 1px;
    padding-right: 15px;
    padding-left: 15px;
    text-align: left;
    background: #6b00b3;
    bottom: 1px;
    solid: #000000;
    width: 130vw;
    max-height: 10px;
}

.full-width {
    width: 100vw;
}
.btn-navBar{
    text-decoration: none;
    padding: 6px;
    margin-right: 10px;
    float: none;
    color: #138153;
    font-weight: 100;
    font-size: 10px;
    box-shadow: none;
    background-color: #138153;
    border: 100px;
}
.btn-navBar:hover{
    color: #2a0046;
    cursor: pointer;
}

hr {
    border: 0; /* Elimina el borde superior por defecto */
    border-top: 10px solid #138153; /* Añade una línea sólida de color gris claro */
    width: 100%; /* Define el ancho de la línea */
    margin: 0; /* Elimina los márgenes superior e inferior */
}


/*footer*/

footer {
    position: static; /* Posiciona el pie de página fijo en la parte inferior */
    bottom: 5px; /* Ajusta el borde inferior del pie de página a la parte inferior de la página */
    left: 0; /* Ajusta el borde izquierdo del pie de página al borde izquierdo de la página */
    width: 100%; /* Define el ancho del pie de página */
    background: lightgrey; /* Define el color de fondo del pie de página */
    text-align: center; /* Centra el contenido del pie de página */
    padding: 1px; /* Agrega espacio interior al pie de página */
    color: #138153;
    margin-bottom: 1%; /* Agrega un margen inferior de 10px al pie de página */

}


/* Ajustes para dispositivos móviles */
@media only screen and (max-width: 600px) {
    #sidebar {
        width: 100%; /* Establecer el ancho al 100% en dispositivos móviles */
        margin: 0; /* Eliminar los márgenes para adaptarse mejor a dispositivos móviles */
        margin-top: 20px; /* Ajustar el margen superior según sea necesario */
        margin-bottom: 20px; /* Ajustar el margen inferior según sea necesario */
    }
}

@media only screen and (max-width: 600px) {
     {
        width: 100%; /* Establecer el ancho al 100% en dispositivos móviles */
        margin: 0; /* Eliminar los márgenes para adaptarse mejor a dispositivos móviles */
        margin-top: 20px; /* Ajustar el margen superior según sea necesario */
        margin-bottom: 20px; /* Ajustar el margen inferior según sea necesario */
    }
}
